import React, { forwardRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import {
  FooterBottom,
  FooterColumn,
  FooterColumns,
  FooterMain,
  FooterNewsletter,
  FooterTop,
  MainContainer
} from './index.style'
import Container from '@components/Container'
import Logo from '@svgs/Logo'
import { Caption, Label } from '@components/TextStyles'
import Spacer from '@components/Spacer'
import SubscribeForm from '@components/SubscribeForm'
import CMSLink from '@components/CMSLink'

const Footer = forwardRef(({ smoothScrollIsActive }, ref) => {
  const {
    contentfulFooter: { newsletterText, socials, details, locations },
    contentfulWhatWeDo,
  } = useStaticQuery(
    graphql`
      query {
        contentfulFooter {
          newsletterText
          socials {
            ... on Node {
              ...Link
            }
          }
          details {
            ... on Node {
              ...Link
            }
          }
          locations
        }
        contentfulWhatWeDo {
          slug
        }
      }
    `
  )

  const columns = [
    {
      title: 'Socials',
      items: socials,
    },
    {
      title: 'Details',
      items: details,
    },
    {
      title: 'Hubs',
      items: locations.map(location => ({
        text: location,
        page: { slug: contentfulWhatWeDo.slug },
      })),
    },
  ]

  return (
    <FooterMain ref={ref} smoothScrollIsActive={smoothScrollIsActive}>
      <Container>
        <MainContainer>
          <FooterTop>
            <FooterNewsletter>
              <SubscribeForm text={newsletterText} />
            </FooterNewsletter>

            <FooterColumns>
              {React.Children.toArray(
                columns.map(column => (
                  <FooterColumn>
                    <Caption as="h2">{column.title}</Caption>

                    {React.Children.toArray(
                      column.items.map(item => (
                        <>
                          <Spacer size={[10, 13]} />
                          <Label as="h3">
                            <CMSLink link={item} />
                          </Label>
                        </>
                      ))
                    )}
                  </FooterColumn>
                ))
              )}
            </FooterColumns>
          </FooterTop>
          <Spacer size={[50, 20]} />
          <Logo />
          <FooterBottom>
            <Label>&copy; {new Date().getFullYear()} Brave Bison</Label>

            <Label>A DIFFERENT BEAST</Label>
          </FooterBottom>
        </MainContainer>
      </Container>
    </FooterMain>
  )
})

export default Footer
