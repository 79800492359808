import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import PageTransitionLink from '@components/PageTransitionLink'
import Logo from '@svgs/Logo'
import { HeaderLogoMain } from './index.style'
import { useStore } from '@Store/'

const HeaderLogo = ({ pathname, hideHeader, scrollDirection, themeColor, title }) => {
  const [store] = useStore()
  const { mobileNavIsOpen } = store
  const $el = useRef()
  const rect = useRef()
  const [isHome, setIsHome] = useState();

  useEffect(() => {
    gsap.set($el.current, {
      yPercent: 0,
      opacity: 1,
    })
  }, [])

  useEffect(() => {
      if(pathname === '/') {
        setIsHome(true);
      } else {
        setIsHome(false);
      }
  }, [pathname])

  useEffect(() => {
    gsap.to($el.current, {
        yPercent: 0,
        duration: 0.2,
        ease: 'out',
      })
  }, [pathname, hideHeader])

  return (
    <HeaderLogoMain
      ref={$el}
      show={
        pathname !== '/' ||
        (pathname === '/' && hideHeader && scrollDirection === -1)
      }
    >
      <PageTransitionLink ref={rect} to="" aria-label={title}>
        {isHome && <h1><Logo fill={themeColor} mobileNavIsOpen={mobileNavIsOpen} /></h1>}
        
        {!isHome && <Logo fill={themeColor} mobileNavIsOpen={mobileNavIsOpen} title={title}/>}
      </PageTransitionLink>
    </HeaderLogoMain>
  )
}

HeaderLogo.propTypes = {
  pathname: PropTypes.string,
  themeColor: PropTypes.string,
}

export default HeaderLogo
