import styled from 'styled-components'
import { setVh } from '@styles/utils/conversion.style'
import { colors } from '@styles/vars/colors.style'
import { mq } from '@styles/vars/media-queries.style'
import { cubicBezier } from '@styles/vars/cubicBezier.style'
import { zIndex } from '@styles/vars/zIndex.style'
import { PageTransitionLinkMain } from '@components/PageTransitionLink/index.style'
import { Caption } from '@styles/vars/textStyles.style'
import { font } from '@styles/vars/font.style'
import { clamp } from '@styles/utils/conversion.style'

const mobileOpenDuration = 0.5
const headerTopAnimateDelay = 0.055

export const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(
    ${props =>
      props.hideHeader ? (props.scrollDirection === -1 ? '0' : '-100%') : '0'}
  );
  z-index: ${zIndex.header};
  background-color: ${props =>
    props.showHeaderBackground
      ? props.backgroundColor
      : props.hideHeader ? props.backgroundColor : 'transparent'};
  transition: background-color 0.2s ${cubicBezier.inOut}${props => (props.showHeaderBackground ? ' 0.2s' : '')},
    transform 0.2s ${cubicBezier.inOut};
`

export const SkipToContent = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-200%);
`

export const HeaderTop = styled.div`
  ${mq.deskNavMax} {
    position: relative;
    transform: translateY(
      ${props =>
        props.mobileNavIsOpen
          ? 'calc((var(--vh, 1vh) * 100) - 100% - var(--cookieBarHeight, 0px))'
          : '0'}
    );
    z-index: ${zIndex.mobileNavButtons};
    transition: transform
      ${props =>
        props.mobileNavIsOpen
          ? mobileOpenDuration - headerTopAnimateDelay
          : mobileOpenDuration}s
      ${cubicBezier.inOut}
      ${props => (props.mobileNavIsOpen ? `${headerTopAnimateDelay}s` : '')};
  }
`

export const HeaderMain = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mq.deskNavMax} {
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
`

export const HeaderBack = styled.div`
  span {
    display: none;
  }
  font-family: ${font.serif.family};
  position: relative;
  z-index: 1;
  ${mq.tabletL} {
    display: inherit;
    white-space: nowrap;

    color: ${props => props.color};
    margin-left: -200px;
    ${clamp('font-size', 12, 30)}
    span {
      display: block;
    }
  }
  strong {
    margin: 0 5px;
    font-family: ${font.sans.family};
  }
  svg {
    display: inline-block;
    ${clamp('width', 34, 48)}
    ${clamp('margin-left', 12, 40)}
    max-width: unset;
  }
`

export const HeaderNav = styled.nav`
  display: none;
  min-height: 68px;
  ${mq.deskNav} {
    display: block;
  }
`

export const HeaderSubMenu = styled.ul`
  padding-bottom:10px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.25s ease;
  position: absolute;
  top: 100%;
  width: 200px;
  left: 0;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  a {
    span {
      font-weight: normal;
    }
    &:hover span{
      font-weight: bold;
    }
  }
  ${PageTransitionLinkMain} {
    position: relative;
    display: block;
    padding: 1rem 1.4rem;
    text-decoration: none;
    transition: color 0.2s ${cubicBezier.inOut};

    &:hover span {
      opacity: 1;
    }

    span {
      position: relative;
      opacity: ${props => (props.faded ? 0.2 : 1)};
      transition: opacity 0.2s ${cubicBezier.inOut};
      text-align: left;
      font-size: 12px;
    }
  }
`
export const CustomLink = styled.a`
  position: relative;
  display: block;
  padding: 0.5rem 3.4rem;
  ${mq.tabletL} {
    padding: 1rem 1.4rem;
  }
  text-decoration: none;
  transition: color 0.2s ${cubicBezier.inOut};
  flex: 1;

  &:hover span {
    opacity: 1;
  }

  span {
    position: relative;
    ${mq.tabletLMax} {
      opacity: 0.2;
    }
    text-align: left;
    font-size: 12px;
  }
`

export const ButtonLink = styled.a`
  position: relative;
  display: block;
  padding: 0.5rem 3.4rem;
  cursor: pointer;
  ${mq.tabletL} {
    padding: 1rem 1.4rem;
  }
  text-decoration: none;
  transition: color 0.2s ${cubicBezier.inOut};
  flex: 1;

  &:hover span {
    opacity: 1;
  }

  span {
    position: relative;
    ${mq.tabletLMax} {
      opacity: 0.2;
    }
    text-align: left;
    font-size: 12px;
  }
`
export const HeaderSubMenuMobile = styled.ul`
  padding-bottom:10px;
  padding-left: 23px;
  flex-basis: 100%;
  height: 0;
  overflow: hidden;
  transition: height 0.25s ease;
  ${PageTransitionLinkMain} {
    position: relative;
    display: block;
    padding: .5rem 3.4rem;
    text-decoration: none;
    transition: color 0.2s ${cubicBezier.inOut};
    flex: 1;

    &:hover span {
      opacity: 1;
    }

    span {
      position: relative;
      opacity: ${props => (props.faded ? 0.2 : 1)};
      transition: opacity 0.2s ${cubicBezier.inOut};
      opacity: 0.2;
      text-align: left;
      font-size: 12px;
    }
  }
`

export const SubMenuButton = styled.button`
  width: 55px;
  height: 55px;
  background: transparent;
  border: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transition: transform 0.2s ${cubicBezier.inOut};
    transform: ${props => (props.isActive ? 'rotate(0)' : 'rotate(-90deg)')};
  }
`

export const HeaderMenu = styled.ul`
  display: none;

  ${mq.deskNav} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    text-align: right;
  }
`

export const HeaderMenuItemMain = styled.li`
  display: flex;
  align-items: stretch;
  color: ${props => props.hideHeader ? props.color : props.showHeaderBackground ? props.color : colors.white};
  position: relative;

  

  &:last-child {
    margin-right: -2.4rem;
  }

  ${Caption} {
    display: block;
  }
  &:hover {
    > ul {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export const HeaderMenuLink = styled.div`
  position: relative;
  opacity: 0;
  ${PageTransitionLinkMain} {
    position: relative;
    display: block;
    padding: 2.6rem 2.4rem;
    text-decoration: none;
    transition: color 0.2s ${cubicBezier.inOut};
    display: flex;

    svg {
      width: 8px;
      height: 16px;
      margin-left: 5px;
    }

    &:hover span {
      opacity: 1;
    }

    span {
      position: relative;
      opacity: ${props => (props.faded ? 0.2 : 1)};
      transition: opacity 0.2s ${cubicBezier.inOut};
    }
  }
`

export const HeaderMenuItemAttractionField = styled.div`
  position: absolute;
  top: 2.6rem;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
`

export const HeaderMenuDot = styled.div`
  position: relative;
  width: 0.2rem;
  opacity: 0;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 0.2rem;
    border-radius: 50%;
    background-color: currentColor;
  }

  ${mq.deskNavMax} {
    margin-left: 2rem;
  }
`

export const HeaderBackLink = styled.div`
  position: absolute;
  top: 0;
  right: -1.2rem;
  bottom: 0;
  width: 4.4rem;
  opacity: 0;

  ${mq.deskNavMax} {
    display: none;
  }

  ${PageTransitionLinkMain} {
    width: 100%;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-indent: 200%;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 2rem;
      height: 2rem;
      background-color: ${props => props.color};
      border-radius: 50%;
      transition: background-color 0.15s ${cubicBezier.inOut};
    }
  }
`

export const MobileNav = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: ${props => (props.open ? 'translateY(0)' : 'translateY(-100%)')};
  width: 100%;
  ${setVh('height', 100)}
  display: flex;
  align-items: flex-start;
  z-index: ${zIndex.mobileNav};
  pointer-events: ${props => (props.open ? undefined : 'none')};
  color: ${colors.white};
  text-align: left;
  background-color: ${colors.charcoal};
  transition: transform ${mobileOpenDuration}s ${cubicBezier.inOut};

  ${mq.deskNav} {
    display: none;
  }
`

export const MobileNavMain = styled.nav`
  width: 100%;
`

export const MobileMenu = styled.ul`
  list-style: none;
  padding-top: 2rem;
`

export const MobileMenuItem = styled.li`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > ${PageTransitionLinkMain} {
    flex: 1;
    display: block;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    font-weight: ${font.sans.weight.extrabold};
    font-size: 1.8rem;
    line-height: 1;
    text-transform: uppercase;
    text-decoration: none;
    opacity: ${props => (props.faded ? 0.2 : 1)};
    transition: opacity 0.2s ${cubicBezier.inOut};
  }
`
