import gsap from 'gsap'
import React, { useEffect, useRef } from 'react'
import { LogoSvg } from './index.style'

const Logo = ({
  width = 1307,
  height = 176,
  fill = 'currentColor',
  responsive = true,
  mobileNavIsOpen,
  title = 'Bravebison'
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }
  const $rSans = useRef()
  const $rSerif = useRef()
  const $vSans = useRef()
  const $vSerif = useRef()
  const $aSans = useRef()
  const $aSerif = useRef()
  const $eSans = useRef()
  const $eSerif = useRef()
  const tl = useRef()

  useEffect(() => {
    const tweenGap = 0.4

    tl.current = gsap.timeline({
      paused: true,
      repeat: -1,
    })

    tl.current
      .set(
        $rSans.current,
        {
          opacity: 0,
        },
        0
      )
      .set(
        $rSerif.current,
        {
          opacity: 1,
        },
        0
      )
      .set(
        [$rSerif.current, $vSans.current],
        {
          opacity: 0,
        },
        tweenGap
      )
      .set(
        [$rSans.current, $vSerif.current],
        {
          opacity: 1,
        },
        tweenGap
      )
      .set(
        [$vSerif.current, $eSans.current],
        {
          opacity: 0,
        },
        2 * tweenGap
      )
      .set(
        [$vSans.current, $eSerif.current],
        {
          opacity: 1,
        },
        2 * tweenGap
      )
      .set(
        $eSerif.current,
        {
          opacity: 0,
        },
        3 * tweenGap
      )
      .set(
        $eSans.current,
        {
          opacity: 1,
        },
        3 * tweenGap
      )

    return () => {
      tl.current.kill()
    }
  }, [])

  const startAnimation = () => {
    tl.current.repeat(-1)

    if (!tl.current.isActive()) {
      tl.current.play()
    } else {
      tl.current.restart()
    }
  }

  const stopAnimation = () => {
    const repeats = Math.floor(tl.current.totalTime() / tl.current.duration())

    tl.current.repeat(repeats)
  }

  return (
    <LogoSvg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1307 176"
      role="img"
      aria-labelledby="logo-title"
      focusable="false"
      mobileNavIsOpen={mobileNavIsOpen}
      onMouseEnter={startAnimation}
      onMouseLeave={stopAnimation}
      {...svgAttributes}
    >
      <title id="logo-title">{title}</title>
      <g fill={fill}>
        <path d="M88.1 0c20.8 0 36.7 1.4 45.8 5.9 13.2 6.1 22.3 19.8 22.3 37.3 0 17.4-7.3 30-27.9 38.6 22 4.9 33.1 18.8 33.1 41 0 16.9-7.8 30.9-19.9 39.5-10.5 7.5-25.3 10-50.6 10H0V0h88.1ZM58.2 63.9h26.1c10.5 0 13.2-3.7 13.2-10 0-6.1-3.5-9.3-12.9-9.3H58.2v19.3Zm0 63.6h25.9c14.2 0 16.4-4.6 16.4-11 0-7-4-10.5-16.2-10.5H58.2v21.5Z" />
        <path
          ref={$rSans}
          d="M165.602 172.1V43.3h52.7v17.8c6.3-11 17.8-19.6 32.6-19.6 3.7 0 5.8.3 10.5 1.5v47.3c-6-2.6-9.5-3.2-15-3.2-8.3 0-15.3 2.3-19.9 7.5-4.7 5.2-5.8 12-5.8 25.7v51.8h-55.1Z"
        />
        <path
          ref={$rSerif}
          opacity="0"
          d="M271.399 52.8c-10 22.2-19.5 35.4-28.6 35.4-4.2 0-7.6-2.4-12.2-7.1-4.2-4.2-7.1-6.6-10.7-6.6-1.7 0-4.4 2.4-6.1 6.1v80.3l11.5 9.5v1.5h-67.7v-1.5l11.2-9.5V69.6l-18.1 9-1-1.2c.2-.5 2-2.7 4.6-6.8 11-15.6 16.1-22.5 23.7-27.8 3.7-2.7 9.8-3.9 14-3.9 11.7 0 18.8 7.1 18.8 26.9 0 3.2 0 5.4-.2 7.1h2l4.6-6.8c12.9-18.3 19.8-27.3 23-27.3 2.2 0 5.1 2.2 10 6.8 6.1 5.9 10.3 9 14.4 9 2.9 0 3.9-.5 5.6-2.4l1.2.6Z"
        />
        <path
          ref={$aSans}
          opacity="0"
          d="M300.1 175.2c-25.8 0-41.6-15-41.6-38.8 0-17.3 8.5-27.1 24.3-33.8 17.3-7.5 42.4-10 56.4-11.5v-4.3c0-7.8-3.5-11-11.5-11-8.8 0-12.3 4.8-13.3 13L264 85c3.8-29.6 21.1-46.4 67.2-46.4 20.3 0 38.6 4 48.9 12.5 9.5 7.8 13.3 18.8 13.3 39.6v50.4c0 13 .8 24.6 3.5 30.8h-53.4c-.8-3.5-1.5-8-1.8-13.5-10.8 12.8-24.6 16.8-41.6 16.8Zm39.1-57.4c-8.8 1-17.8 3-22.8 5.3-3.3 1.5-4.8 4-4.8 7.5 0 4.3 2.8 8 9.3 8 10.8 0 18.3-6.8 18.3-19v-1.8Z"
        />
        <path
          ref={$aSerif}
          d="m407.699 135.1 1.1 1.2-6.2 7.5c-17.9 22.1-27 31.1-40.6 31.1-13.6 0-18.9-7.3-18.9-27 0-3.2 0-5.3.3-7h-1.4c-11.1 21.1-28.3 34.3-51.3 34.3-19.6 0-32.9-10.2-32.9-26 0-20.4 19.6-35.9 49.4-47.8l34.5-11.9c0-24-9.5-36.7-22.3-36.7-9.4 0-15.9 5.1-15.9 15.5 0 6.6 1.7 14.3 5.1 23.6l-52.5 12.9-.9-1.5c9.9-18.5 21.6-32.6 34.7-42.3 13.1-9.5 24.7-15.8 34.9-18.5 9.9-2.7 17.6-3.9 22.4-3.9 34.9 0 42.9 14.3 42.9 39.4 0 6.1-.3 12.4-.9 18.9l-2.6 43.7v3.6l21.1-9.1Zm-66.6-2.5.6-37.6c-23 13.4-35.6 26.7-35.6 37.4 0 9 6.5 13.4 12.5 13.4 6.5 0 17.1-4.4 22.5-13.2Z"
        />
        <path
          ref={$vSans}
          d="M429 172.3 387.5 43.5h59.2c5.8 25.9 11.5 51.5 17 77.7 4.5-25.7 9-42.9 17.3-77.7h56.1l-40.2 128.8H429Z"
        />
        <path
          ref={$vSerif}
          opacity="0"
          d="M482.798 43.3v1.4l3.5 3.2c9.2 8.3 14.8 19.7 17.4 31.7 3.7 17.3 0 33.9-7.9 49.6l-3.9 7.9h-2.5l-40.1-83.9 12.9-8.5v-1.4h-75.8v1.4l63.1 127.2h30.2l62.1-127.2v-1.4h-59Z"
        />
        <path
          ref={$eSans}
          d="M597.101 175.2c-45.6 0-70.8-25.1-70.8-68.3 0-41.5 27.3-68.3 67.7-68.3 47.9 0 69.7 29.8 69.7 79.7h-82c1 14.3 9.5 17.4 16 17.4 6.3 0 12-3.4 14.1-13.5l48.6 14.9c-11.3 22.5-30.8 38.1-63.3 38.1Zm.2-100c-7.6 0-13.8 3.9-15.5 14.9h30.1c-1.6-10.7-7.4-14.9-14.6-14.9Z"
        />
        <path
          ref={$eSerif}
          opacity="0"
          d="m616.601 102 28.9-11.7 11.3-4.5c1.2-.5 2-.9 2-2.3 0-.8-.1-2.6-.3-3.6-2.2-10.2-6.7-17.8-14.3-25.5-10.6-10.8-27.1-15.9-43-15.9-26.2-.1-51.3 11-65.6 34.5-9.2 15.3-10.7 29.3-8.3 45.1 5.6 36.3 41.3 61.8 78.3 56.1 11-1.7 24.801-6.6 33.301-13.9 3.3-2.8 12.7-20.1 19-44.3l-1.5-.8c-18.2 32.5-74.401 31-81.401-12.1l37.7-.4c1.4 0 2.7-.2 3.9-.7Zm-42.1-3.2c-1.6-12-2.099-22.9 1.401-34.7 1.3-4.3 4.099-8.8 8.199-10.9 8.5-4.2 15.7 2 19.9 9.5 5.9 10.6 6.901 22.2 6.901 36.1h-36.401Z"
        />
        <path d="M776.801.2c20.8 0 36.7 1.4 45.8 5.9 13.2 6.1 22.3 19.8 22.3 37.3 0 17.4-7.3 30-27.9 38.6 22 4.9 33.1 18.8 33.1 41 0 16.9-7.8 30.9-19.9 39.5-10.5 7.5-25.3 10-50.6 10h-90.8V.2h88Zm-29.9 63.9h26.1c10.5 0 13.2-3.7 13.2-10 0-6.1-3.5-9.3-12.9-9.3h-26.4v19.3Zm0 63.6h25.9c14.2 0 16.4-4.6 16.4-11 0-7-4-10.5-16.2-10.5h-26.1v21.5Z" />
        <path d="M854.301 34.3V.2h54.3v34.1h-54.3Zm0 138.2V46.9h54.3v125.6h-54.3Z" />
        <path d="M936.202 120.6c7.3 11.4 20.3 17.8 32.3 17.8 6.8 0 9.1-2.5 9.1-5.2 0-3-1.4-5.2-8.1-7.4l-19.4-6.8c-21-7.2-34.5-18.6-34.5-38.2 0-23.5 16.7-42.1 59.7-42.1 21.8 0 42.598 7.7 56.498 20.6l-26.1 29.5c-8.298-10.1-18.798-15.1-27.398-15.1-7 0-8.8 2.5-8.8 5.7 0 3.5 2.4 4.7 12.9 8.5l16.7 6c21.498 7.7 32.298 19.9 32.298 36.9 0 27.8-25.9 44.5-60.598 44.5-22.8 0-46-8.2-61.7-24.3l27.1-30.4Z" />
        <path d="M1099.7 175.2c-44.1 0-70.5-24.3-70.5-67.2 0-43.4 28.3-69.4 72.2-69.4 44.1 0 69 25.6 69 70.2 0 45.6-33.7 66.4-70.7 66.4Zm.7-95.5c-9.4 0-16.4 7.1-16.4 26 0 19.9 6.8 28.3 16.4 28.3 9.4 0 15.1-7.7 15.1-26.8.1-20.1-6-27.5-15.1-27.5Z" />
        <path d="M1253.3 172.3v-68.6c0-5.2-.5-9.3-1.9-11.8-1.7-3.3-4.9-4.5-9.1-4.5-4.4 0-7.6 1.4-9.7 4.7-1.9 3-3 8.5-3 14.8v65.3h-53.8V43.3h52.5V59c10.5-11.3 22.9-17.5 40.6-17.5 16.9 0 27.9 6.6 33.5 18.8 3.7 8 4.6 19 4.6 31.5v80.4h-53.7v.1Z" />
      </g>
    </LogoSvg>
  )
}

export default Logo
