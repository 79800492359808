import React, { forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import PageTransitionLink from '@components/PageTransitionLink'
import Container from '@components/Container'
import MenuToggle from '@components/MenuToggle'
import {
  HeaderBackLink,
  HeaderContainer,
  HeaderMain,
  HeaderMenu,
  HeaderMenuDot,
  HeaderMenuItemAttractionField,
  HeaderMenuItemMain,
  HeaderMenuLink,
  HeaderNav,
  SkipToContent,
  HeaderTop,
  MobileMenu,
  MobileMenuItem,
  MobileNav,
  MobileNavMain,
  HeaderSubMenu,
  HeaderSubMenuMobile,
  SubMenuButton,
  HeaderBack,
  CustomLink,
  ButtonLink
} from './index.style'
import HeaderLogo from '@components/HeaderLogo'
import { Caption } from '@components/TextStyles'
import { colors } from '@styles/vars/colors.style'
import gsap from 'gsap'
import { setCaseStudyParentSlug, hideMobileNav } from '@Store/'
import { useStore } from '@Store/'
import { setShowHeaderBackground } from '@Store/'
import IconArrowDown from '@svgs/IconArrowDown'
import IconBack from '@svgs/IconBack'
import IconCaret from '@svgs/IconCaret'

const HeaderMenuItem = ({
  refs,
  index,
  color,
  active,
  faded,
  slug,
  label,
  childs,
  tabIndex,
  pathname
}) => {
  const [store, dispatch] = useStore()
  const indexRoute = (index - 1) * 2

  const {
    headerColor,
    headerBackground,
    showHeaderBackground,
    hideHeader,
    onBannerPosition
  } = store

  const changeService = (slug) => {
    const event = new CustomEvent("changeService", {'detail' : slug});
    window.dispatchEvent(event);
  }
  
  return (
    <HeaderMenuItemMain color={color} hideHeader={hideHeader} showHeaderBackground={showHeaderBackground} active={active} faded={faded}>
      {index > 0 && (
        <HeaderMenuDot ref={ref => (refs.current[indexRoute + 1] = ref)} />
      )}

      <HeaderMenuLink
        ref={ref => (refs.current[index === 0 ? 0 : indexRoute + 2] = ref)}
      >
        <PageTransitionLink to={slug} underline={false} tabIndex={tabIndex}>
          <HeaderMenuItemAttractionField />
          <Caption as="span">{label}</Caption>
        </PageTransitionLink>
      </HeaderMenuLink>
      {childs &&
        <>
        <HeaderSubMenu backgroundColor={headerBackground} color={headerColor}>
          <PageTransitionLink to={`what-we-do/organic-performance`} underline={false} tabIndex={tabIndex}>
            <HeaderMenuItemAttractionField />
            <Caption as="span" >Organic Performance</Caption>
          </PageTransitionLink>
          <PageTransitionLink to={`what-we-do/paid-performance`} underline={false} tabIndex={tabIndex}>
            <HeaderMenuItemAttractionField />
            <Caption as="span" >Paid Performance</Caption>
          </PageTransitionLink>
          <PageTransitionLink to={`what-we-do/technology-and-experience`} underline={false} tabIndex={tabIndex}>
            <HeaderMenuItemAttractionField />
            <Caption as="span" >Technology & Experience</Caption>
          </PageTransitionLink>
          <CustomLink href="https://www.socialchain.com/" target="_blank">
            <HeaderMenuItemAttractionField />
            <Caption as="span" >Social & Influencer</Caption>
          </CustomLink>
          <CustomLink href="https://www.engagedigitalpartners.com/" target="_blank">
            <HeaderMenuItemAttractionField />
            <Caption as="span" >Sports Marketing</Caption>
          </CustomLink>
          <PageTransitionLink to={`what-we-do/digital-media-network`} underline={false} tabIndex={tabIndex}>
            <HeaderMenuItemAttractionField />
            <Caption as="span" >Digital Media Network</Caption>
          </PageTransitionLink>
          {(pathname === '/what-we-do/') ?
            <ButtonLink onClick={() => {
                const targetElement = document.getElementById('growth-consultancy');
                if (targetElement) {
                    hideMobileNav(dispatch);
                    window.scrollTo({
                        top: targetElement.offsetTop - 60,
                        left: 0,
                        behavior: "smooth",
                    });
                }
            }}>
                <Caption as="span">Growth Consultancy</Caption>
            </ButtonLink>
            :
            <PageTransitionLink to={`what-we-do/?section=growth-consultancy`}  underline={false} tabIndex={tabIndex}>
              <HeaderMenuItemAttractionField />
              <Caption as="span" >Growth Consultancy</Caption>
            </PageTransitionLink>
          }
        </HeaderSubMenu>
        </>
      }
    </HeaderMenuItemMain>
  )
}

const Header = forwardRef(({ pathname }, ref) => {
  const [store, dispatch] = useStore()
  const $menuItemRefs = useRef([])
  const $backLink = useRef()
  const headerItemsLoadAnimation = useRef()
  const subMenuRef = useRef()
  const [subActive, setSubActive] = useState() 
  const {
    mobileNavIsOpen,
    headerColor,
    headerBackground,
    hideHeader,
    showHeaderBackground,
    showPageMask,
    caseStudyParentSlug,
    scrollDirection,
  } = store

  const themeColor = mobileNavIsOpen ? colors.white : headerColor
  const {
    contentfulAboutUs,
    contentfulOurWorks,
    contentfulWhatWeDo,
    contentfulCulture,
    contentfulCareers,
    contentfulInsights,
    contentfulContact,
    allCaseStudySlugs,
    allServicesSlugs,
  } = useStaticQuery(graphql`
    query {
      contentfulAboutUs {
        slug
      }
      contentfulOurWorks {
        slug
      }
      contentfulWhatWeDo {
        slug
      }
      contentfulCulture {
        slug
      }
      contentfulCareers {
        slug
      }
      contentfulInsights {
        slug
      }
      contentfulContact {
        slug
      }
      allCaseStudySlugs: allContentfulCaseStudy {
        nodes {
          slug
        }
      }
      allServicesSlugs: contentfulWhatWeDo {
        services {
          title
          slug
        }
      }
    }
  `)

  useEffect(() => {
    if(subActive) {
      subMenuRef.current.style.height = subMenuRef.current?.children[0].clientHeight + 'px';
    }else {
      subMenuRef.current.style.height = 0;
    }
  }, [subActive])

  const menuItems = [
    { label: 'About Us', slug: contentfulAboutUs.slug },
    { label: 'Our Work', slug: contentfulOurWorks.slug },
    { label: 'What We Do', slug: contentfulWhatWeDo.slug, childs: allServicesSlugs.services },
    { label: 'Culture', slug: contentfulCulture.slug },
    { label: 'Careers', slug: contentfulCareers.slug },
    { label: 'Insights', slug: contentfulInsights.slug },
    { label: 'Contact', slug: contentfulContact.slug },
  ]

  const isItemActive = slug => {
    // Handle Home page separately
    if (slug === '/') {
      return pathname === slug
    }

    return pathname.indexOf(slug) > -1
  }

  const isItemFaded = (slug, isMobileItem = false) => {
    const pageItems = menuItems

    if (isMobileItem) {
      pageItems.push({ slug: '' })
    }

    const activePageIsInTheMenu = pageItems.reduce((prev, item) => {
      if (pathname.includes(item.slug)) {
        return true
      } else {
        return prev
      }
    }, false)

    if (activePageIsInTheMenu) {
      return !isItemActive(slug)
    } else {
      return false
    }
  }

  // Setup the header item build on animations
  useLayoutEffect(() => {
    headerItemsLoadAnimation.current = gsap.timeline({
      paused: true,
    })

    gsap.set([$menuItemRefs.current, $backLink.current], {
      yPercent: -100,
      opacity: 1,
    })

    headerItemsLoadAnimation.current.to($menuItemRefs.current, {
      yPercent: 0,
      ease: 'out',
      duration: 0.15,
      stagger: -0.03,
      delay: 0.15,
    })

    return () => {
      headerItemsLoadAnimation.current.kill()
    }
  }, [])

  // Run the header item and logo build on animations once the page mask has hidden
  useEffect(() => {
    if (!showPageMask) {
      if (!caseStudyParentSlug) {
        headerItemsLoadAnimation.current.play()
        gsap.to($backLink.current, {
          yPercent: -100,
          ease: 'in',
          duration: 0.15,
        })
      } else {
        headerItemsLoadAnimation.current.reverse()
        gsap.to($backLink.current, {
          yPercent: 0,
          ease: 'out',
          delay: 0.7,
          duration: 0.15,
        })
      }
    }
  }, [showPageMask, caseStudyParentSlug])

  useEffect(() => {
    const caseStudySlugs = allCaseStudySlugs.nodes.map(node => node.slug)

    const isCaseStudyPage = caseStudySlugs.reduce((value, currValue) => {
      if (pathname.includes(`/${currValue}`)) {
        return true
      }

      return value
    }, false)

    setCaseStudyParentSlug(dispatch, false)

    setShowHeaderBackground(dispatch, false)
  }, [dispatch, pathname, allCaseStudySlugs.nodes])

  return (
    <>
    <HeaderContainer
      ref={ref}
      hideHeader={hideHeader}
      showHeaderBackground={showHeaderBackground}
      backgroundColor={headerBackground}
      showPageMask={showPageMask}
      scrollDirection={scrollDirection}
    >
      <SkipToContent id="skip-to-content" href="#smooth-wrapper">
        Skip to content
      </SkipToContent>


      <HeaderTop mobileNavIsOpen={mobileNavIsOpen}>
        <Container fullWidth>
          <HeaderMain>
            <HeaderLogo
              pathname={pathname}
              hideHeader={hideHeader}
              scrollDirection={scrollDirection}
              title="Bravebison"
              themeColor={showHeaderBackground ? themeColor : hideHeader ? themeColor : colors.white}
            />
            {!caseStudyParentSlug && 
              <MenuToggle />
            }

            <HeaderNav>
              <HeaderMenu>
                {React.Children.toArray(
                  menuItems.map((item, itemIndex) => (
                    <HeaderMenuItem
                      refs={$menuItemRefs}
                      index={itemIndex}
                      color={themeColor}
                      showHeaderBackground={showHeaderBackground}
                      active={isItemActive(item.slug)}
                      faded={isItemFaded(item.slug)}
                      slug={item.slug}
                      childs={item.childs}
                      label={item.label}
                      tabIndex={!caseStudyParentSlug ? 0 : -1}
                      pathname={pathname}
                    />
                  ))
                )}
              </HeaderMenu>
            </HeaderNav>
            {caseStudyParentSlug && 
              <PageTransitionLink to={'what-we-do/performance'} underline={false}>
                <HeaderBack color={themeColor}><span>Back to <strong>Brave Bison</strong> Performance </span><IconBack fill={themeColor}/></HeaderBack>
              </PageTransitionLink>
            }
          </HeaderMain>
        </Container>
      </HeaderTop>

      <MobileNav open={mobileNavIsOpen}>
        <MobileNavMain>
          <MobileMenu>
            <MobileMenuItem
              index={0}
              open={mobileNavIsOpen}
              color={themeColor}
              faded={isItemFaded('/')}
            >
              <PageTransitionLink to="">Home</PageTransitionLink>
            </MobileMenuItem>

            {React.Children.toArray(
              menuItems.map(item => (
                <MobileMenuItem
                  color={themeColor}
                  faded={isItemFaded(item.slug, true)}
                >
                  <HeaderMenuDot />

                  <PageTransitionLink to={item.slug}>
                    {item.label}
                  </PageTransitionLink>
                  {item.childs && 
                    <>
                      <SubMenuButton onClick={()=>{setSubActive(!subActive)}} isActive={subActive}>
                        <IconArrowDown responsive={false} />
                      </SubMenuButton>
                      <HeaderSubMenuMobile faded={isItemFaded(item.slug, true)} ref={subMenuRef} backgroundColor={headerBackground} color={headerColor}>
                        <div>
                        <PageTransitionLink to={`what-we-do/organic-performance`} underline={false}>
                          <HeaderMenuItemAttractionField />
                          <Caption as="span" >Organic Performance</Caption>
                        </PageTransitionLink>
                        <PageTransitionLink to={`what-we-do/paid-performance`} underline={false}>
                          <HeaderMenuItemAttractionField />
                          <Caption as="span" >Paid Performance</Caption>
                        </PageTransitionLink>
                        <PageTransitionLink to={`what-we-do/technology-and-experience`} underline={false}>
                          <HeaderMenuItemAttractionField />
                          <Caption as="span" >Technology & Experience</Caption>
                        </PageTransitionLink>
                        <CustomLink href="https://www.socialchain.com/">
                          <HeaderMenuItemAttractionField />
                          <Caption as="span" >Social & Influencer</Caption>
                        </CustomLink>
                        <CustomLink href="https://www.engagedigitalpartners.com/">
                          <HeaderMenuItemAttractionField />
                          <Caption as="span" >Sports Marketing</Caption>
                        </CustomLink>
                        <PageTransitionLink to={`what-we-do/digital-media-network/`} underline={false}>
                          <HeaderMenuItemAttractionField />
                          <Caption as="span" >Digital Media Network</Caption>
                        </PageTransitionLink>
                        {(pathname === '/what-we-do/') ?
                          <ButtonLink onClick={() => {
                              const targetElement = document.getElementById('growth-consultancy');
                              if (targetElement) {
                                  hideMobileNav(dispatch);
                                  window.scrollTo({
                                      top: targetElement.offsetTop - 60,
                                      left: 0,
                                      behavior: "smooth",
                                  });
                              }
                          }}>
                              <Caption as="span">Growth Consultancy</Caption>
                          </ButtonLink>
                          :
                          <PageTransitionLink to={`what-we-do/?section=growth-consultancy`}  underline={false}>
                            <HeaderMenuItemAttractionField />
                            <Caption as="span" >Growth Consultancy</Caption>
                          </PageTransitionLink>
                        }
                        </div>
                      </HeaderSubMenuMobile>
                    </>
                  }
                </MobileMenuItem>
              ))
            )}
          </MobileMenu>
        </MobileNavMain>
      </MobileNav>
    </HeaderContainer>
    </>
  )
})

Header.propTypes = {
  pathname: PropTypes.string,
}

export default Header
